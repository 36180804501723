<template>
  <div>
    <div class="card">
      <div class="card-body position-relative overflow-hidden">
        <div class="row">
          <div class="col-md-4">
            <select v-model="search_data.year_id" v-validate="'required'" name="year_id" id="year_id" class="form-control form-control-sm" >
              <option :value="''">Select a year</option>
              <option v-for="(year, index) in years" :key="index"  :value="year.id">{{ year.year_number }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <select v-model="search_data.month_id" v-validate="'required'" name="month_id" id="month_id" class="form-control form-control-sm" >
              <option :value="''">Select a month</option>
              <option v-for="(month, index) in months" :key="index" :value="month.id">{{ month.month_name }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
          </div>
        </div>
      </div>
    </div>
      <a-spin :spinning="spinning" :delay="delayTime" tip="Loading...">
        <div class="row">
            <div v-for="(hub, index) in hubs" :key="index" class="col-md-4">
              <div class="card">
                  <div class="card-body position-relative overflow-hidden" @click="hubDetails(hub.id, index)" style="cursor: pointer;">
                    <div class="font-size-30 font-weight-bold text-dark mb-n2">{{ hub.name }}</div>
                    <div class="text-uppercase">{{ hub.address }}</div>
                    <div class="text-uppercase mt-1">
                      <a-tag color="red">Issue: {{ hub.issue }}</a-tag>
                      <a-tag color="orange">Pending: {{ hub.pending }}</a-tag>
                      <a-tag color="orange">Inprogress: {{ hub.inprogress }}</a-tag>
                      <a-tag color="green">Solved: {{ hub.solved }}</a-tag>
                    </div>
                    <div class="text-uppercase mt-1">
                      <a-tag color="blue">Current Month Weekly Rating</a-tag>
                      <br>
                      <template>
                        <span v-for="(week, index) in hub.weeks" :key="index">
                           <a-tag  style="color: red" v-if="week.hasOwnProperty('rating_message')">Week {{ week.week_id }}: Pending Rating</a-tag>
                           <a-tag  style="color: green" v-if="!week.hasOwnProperty('rating_message')">Week {{ week.week_id }}: Rating Given</a-tag>
                        </span>
                      </template>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </a-spin>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: {},
  data() {
    return {
      hubs: [],
      years: [],
      months: [],
      btnLoading: false,
      loading: false,
      spinning: false,
      delayTime: 500,
      search_data: {
        year_id: '',
        month_id: '',
      },
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/admin-scenario/hubs/codes')
        .then(response => {
          const data = response.data
          this.years = data.years
          this.months = data.months
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.btnLoading = true
          this.loading = true
          this.spinning = true
          apiClient.post('api/admin-scenario/hubs', this.search_data)
            .then(response => {
              this.loading = false
              this.btnLoading = false
              this.spinning = false
              const data = response.data
              this.hubs = data.hubs
            })
            .catch(error => {
              this.loading = false
              this.btnLoading = false
              this.spinning = false
              console.log(error)
            })
        }
      })
    },
    hubDetails(hubId, index) {
      this.$router.push({ name: 'adminScenarioIssueHubAllDetails', params: { hub_id: hubId } })
    },
  },
}
</script>

<style scoped>

</style>
